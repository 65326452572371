import React from 'react';
import { graphql } from 'gatsby';
import ArticleBody from '../components/works/ArticleBody';

const WorkArticleTemplate: React.VFC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  location: Location;
}> = ({ data, location }) => {
  return (
    <>
      <ArticleBody data={data} />
    </>
  );
};

export default WorkArticleTemplate;

export const pageQuery = graphql`
  query WorkArticleBySlug($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date
        description
        thumbnail {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 800, placeholder: BLURRED, formats: [PNG])
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
